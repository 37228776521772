import PropTypes from 'prop-types';
import React from 'react';

export default function InputRequiredError({ show }) {
  // TODO: Make more extensible. Be able to take in more types of errors.

  if (!show) {
    return null;
  }

  return (
    <div className="field-level-error">
      <div className="arrow" />
      <div className="field-level-error__content">This is a required field</div>
    </div>
  );
}
InputRequiredError.propTypes = {
  show: PropTypes.bool.isRequired,
};
