import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import Header from './components/Header';
import Content from './components/Content';

import './root.scss';

export default function Root() {
  // Determine if in HV commercial environment
  const isHvEnv =
    ((document.querySelector('meta[name="is_hv_env"]') || '').content || '').toLowerCase() ===
    'true'
      ? true
      : false;

  return (
    <>
      {isHvEnv ? (
        <Router basename="/login">
          <div className="main-display">
            <div className="modal-overlay"></div>
            <div className="header">
              <Header />
            </div>
            <div className="underneath-header">
              <Content />
            </div>
          </div>
        </Router>
      ) : (
        <Router basename="/login/session_timeout">
          <COTSTimeoutOverlay />
        </Router>
      )}
    </>
  );
}

const COTSTimeoutOverlay = ({}) => {
  return (
    <div className="timeout-overlay">
      <div className="timeout-container">
        <div className="timeout-header" />
        <div className="timeout-content">
          <div className="timeout-message">
            Your session has expired, you will need to sign in again to continue.
          </div>
          <button onClick={() => (location.href = '/marketplace/')} className="timeout-button">
            Sign in
          </button>
        </div>
      </div>
    </div>
  );
};
