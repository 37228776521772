import React, { useEffect, useState } from 'react';
import DropdownList from 'react-widgets/lib/DropdownList';

import * as QueryString from 'query-string';
import './activate.scss';
import {
  userRoles,
  defaultUserProfile,
  formatPhoneNumber,
  attemptActivate,
  getUserInfo,
} from './user-profile';
import { redirectToLogin, redirectToMarketplace } from '../../../../../../routing-utils';

/**
 * Fetches activation key `ak` from query params
 */
function getActivationKey() {
  const params = QueryString.parse(window.location.search);
  return params.ak;
}

export default function Activate() {
  // State Management
  const [userProfile, setUserProfile] = useState(defaultUserProfile);
  const [passwordError, setPasswordError] = useState(null);

  useEffect(() => {
    getUser();
  }, []);

  /**
   * Gets user information based on activation key in query param
   */
  function getUser() {
    getUserInfo(getActivationKey()).then((updatedProfile) => {
      setUserProfile(updatedProfile);
    });
  }

  // Updates userProfile with changed first_name
  function onFirstNameChange(event) {
    const fName = event.target.value.trim();
    const updatedProfile = { ...userProfile, ['firstName']: fName };
    setUserProfile(updatedProfile);
  }

  // Updates userProfile with changed last_name
  function onLastNameChange(event) {
    const lName = event.target.value.trim();
    const updatedProfile = { ...userProfile, ['lastName']: lName };
    setUserProfile(updatedProfile);
  }

  // Updates userProfile with changed phone_number
  function onPhoneNumberChange(event) {
    const sanitizedPhone = event.target.value.replace(/[^0-9+]/g, '');
    const updatedProfile = { ...userProfile, ['phoneNumber']: sanitizedPhone };
    setUserProfile(updatedProfile);
  }

  // Updates userProfile with changed role
  function onUserRoleChange(value) {
    if (value === userRoles[0]) {
      value = null;
    }
    const updatedProfile = { ...userProfile, ['role']: value };
    setUserProfile(updatedProfile);
  }

  // Updates userProfile with changed password (no trim)
  function onPasswordChange(event) {
    const updatedProfile = { ...userProfile, ['password']: event.target.value };
    setUserProfile(updatedProfile);
  }

  function onSubmit(event) {
    // Prevent unnecessary page reload
    event.preventDefault();

    // Reset password error
    setPasswordError(null);

    // Attempt to activate the account
    attemptActivate(userProfile, getActivationKey())
      .then((res) => {
        redirectToMarketplace();
      })
      .catch((error) => {
        const errorStatus = error.response.status;
        if (errorStatus === 403) {
          // Invalid activation key, redirect to login
          redirectToLogin();
        } else if (errorStatus === 400) {
          // Password validation error
          const msg = JSON.parse(error.request.response).Error[0];
          setPasswordError(msg);
        }
      });
  }

  function renderPasswordValidationMessage() {
    if (passwordError) {
      return <div className="password-validation-error">{passwordError}</div>;
    }
  }

  return (
    <div className="activate-page">
      {renderPasswordValidationMessage()}
      <div className="activate-container">
        <form onSubmit={onSubmit}>
          <div className="activate-box">
            <div className="activate-box-header">Activate your HealthVerity account</div>
            <div className="activate-box-welcome-msg-section">
              <div className="activate-box-welcome-msg">
                Welcome to HealthVerity, please give us some more information about yourself.
              </div>
            </div>
            <div className="activate-box-contact-details-section">
              <label className="activate-box-section-title"> Contact details </label>
              <div className="activate-box-name-row">
                <input
                  type="text"
                  className="activate-box-input"
                  value={userProfile.firstName}
                  placeholder="First name"
                  onChange={onFirstNameChange}
                  required
                />
                <input
                  type="text"
                  className="activate-box-input"
                  value={userProfile.lastName}
                  placeholder="Last name"
                  onChange={onLastNameChange}
                  required
                />
              </div>
              <input
                type="text"
                className="activate-box-input"
                value={formatPhoneNumber(userProfile.phoneNumber)}
                placeholder="Phone number (optional)"
                onChange={onPhoneNumberChange}
              />
            </div>
            <div className="activate-box-role-section">
              <label className="activate-box-section-title">Role</label>
              <div className="activate-box-dropdown-parent">
                <DropdownList
                  data={userRoles}
                  value={userProfile.role || userRoles[0]}
                  containerClassName="activate-box-dropdown"
                  onChange={onUserRoleChange}
                />
              </div>
            </div>
            <div className="activate-box-password-section">
              <label className="activate-box-section-title">Password</label>
              <input
                type="password"
                className="activate-box-input"
                value={userProfile.password}
                placeholder="Enter Password"
                onChange={onPasswordChange}
                required
              />
              <span className="activate-box-password-requirement">
                Password must be 12-64 characters and contain at least one uppercase letter, one
                lowercase letter, one number, and one special character.
              </span>
            </div>
            <div className="activate-box-button-section">
              <button className="success">Activate</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
