import PropTypes from 'prop-types';
import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import './invalid-code.scss';

export default function InvalidCode({ onLeaveModal, onClickedResetPassword }) {
  return (
    <Modal className="token-expired login-modal" isOpen={true}>
      <ModalHeader>Password reset expired</ModalHeader>
      <ModalBody>
        <div className="alert alert-success">
          The password reset link expired. Please request a new one.
        </div>
        <div className="button-container--align-right">
          <button onClick={onLeaveModal}>Cancel</button>
          <button className="success" onClick={onClickedResetPassword}>
            Reset password
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
}
InvalidCode.propTypes = {
  onLeaveModal: PropTypes.func.isRequired,
  onClickedResetPassword: PropTypes.func.isRequired,
};
