import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Form, Input, Modal, ModalBody, ModalHeader } from 'reactstrap';

import InputRequiredError from '../../../shared/InputRequiredError';

import './send-reset-email.scss';

export default class SendResetEmail extends Component {
  state = {
    emailInputValue: null,
    emailShowRequired: false,
  };

  handleEmailInputChange = (event) => {
    this.setState({ emailInputValue: event.target.value });
  };

  requestPasswordReset = (event) => {
    event.preventDefault();

    const emailMissing = !this.state.emailInputValue;
    this.setState({ emailShowRequired: emailMissing });

    this.props.onRequestPasswordReset(this.state.emailInputValue);
  };

  render() {
    return (
      <Modal className="login-modal" isOpen={true}>
        <ModalHeader>Reset password</ModalHeader>
        <ModalBody>
          <Form className="hv-form" onSubmit={this.requestPasswordReset}>
            <Input
              id="email"
              className="email-input"
              name="email"
              onChange={this.handleEmailInputChange}
              placeholder="Enter email"
            />
            <InputRequiredError show={this.state.emailShowRequired} />

            <div className="button-container--align-right">
              <button type="button" onClick={this.props.onLeaveModal}>
                Cancel
              </button>
              <button className="success" type="submit">
                Reset password
              </button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    );
  }
}
SendResetEmail.propTypes = {
  onLeaveModal: PropTypes.func.isRequired,
  onRequestPasswordReset: PropTypes.func.isRequired,
};
