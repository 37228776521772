import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, NavbarToggler } from 'reactstrap';

import './hv-header.scss';

export default function HvHeader({
  menu,
  menuExists,
  cornerLink,
  applicationLogo,
  onToggleMenuOpen,
}) {
  const menuElement = menuExists && menu;
  const menuToggler = menuExists && (
    <NavbarToggler className="menu-button" onClick={onToggleMenuOpen} />
  );

  return (
    <div>
      {menuElement}
      <Navbar className="navigation-bar" light expand="md">
        <div className="menu-button-container">{menuToggler}</div>
        <img className="header-logo" src={applicationLogo} alt="application logo" />
        <div className="corner-link-container">{cornerLink}</div>
      </Navbar>
    </div>
  );
}

HvHeader.propTypes = {
  menu: PropTypes.object,
  menuExists: PropTypes.bool.isRequired,
  cornerLink: PropTypes.object,
  applicationLogo: PropTypes.string.isRequired,
  onToggleMenuOpen: PropTypes.func,
};
