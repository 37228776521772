import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { ROUTES, changeRoute } from '~/routing-utils.js';
import { logout } from '~/requests';

import LockedOutModal from './LockedOutModal';

export default class LockedOutModalContainer extends Component {
  onLeaveModal = async () => {
    await logout();
    changeRoute(this.props.history, ROUTES.LOGIN);
  };

  render() {
    return <LockedOutModal onLeaveModal={this.onLeaveModal} />;
  }
}
LockedOutModalContainer.propTypes = {
  history: PropTypes.object.isRequired,
};
