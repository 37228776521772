export const ROUTES = {
  LOGIN: '/',
  VERIFY: '/verify/',
  VERIFY_WITH_CODE: '/verify/:code',
  TOS: '/tos',
  ACCOUNT_LOCKED: '/account_locked/',

  RESET_PASSWORD: '/reset_password/',
  RESET_PASSWORD_EMAIL_SENT: '/reset_password/email_sent/',

  SET_PASSWORD_FORM: '/reset_password/:b64UserId/:resetToken',
  SET_PASSWORD_INVALID_CODE: '/reset_password/link_expired',
  PASSWORD_SET: '/reset_password/password_set',

  ACTIVATE: '/activate/',
};

export function changeRoute(history, newPath) {
  history.push({ pathname: newPath, search: history.location.search });
}

export function redirectToLogin() {
  window.location.pathname = '/login/';
}

export function redirectToMarketplace() {
  window.location.pathname = '/marketplace/';
}

export function redirectToKnowledgeBase(kbUrl) {
  window.location.replace(kbUrl);
}
