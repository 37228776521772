import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

import Login from './components/Login';
import Marketing from './components/Marketing';

import './content.scss';
import { ROUTES } from '../../../../routing-utils';
import Activate from './components/Activate';

export default function Content({ marketingContent }) {
  // Don't show marketing on Account Activate page
  return (
    <div className="content-container">
      <Switch>
        <Route path={ROUTES.ACTIVATE}>
          <div className="login-form-container">
            <Activate />
          </div>
        </Route>
        <Route
          component={(props) => {
            return (
              <>
                <div className="login-form-container">
                  <Login history={props.history} />
                </div>
                {marketingContent !== null && (
                  <div className="marketing-container d-none d-lg-block">
                    <Marketing marketingContent={marketingContent} />
                  </div>
                )}
              </>
            );
          }}
        />
      </Switch>
    </div>
  );
}
Content.propTypes = {
  marketingContent: PropTypes.object,
};
