import React from 'react';
import PropTypes from 'prop-types';

export default function CornerLink({ name, href }) {
  return (
    <a className="corner-link" href={href} target="_blank">
      {name}
    </a>
  );
}

CornerLink.propTypes = {
  name: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};
