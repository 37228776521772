import PropTypes from 'prop-types';
import React from 'react';

import HvHeader from './HvHeader';
import CornerLink from './HvHeader/CornerLink';

export default class Header extends React.Component {
  constructor() {
    super();
    this.applicationLogo = require('../../../../shared/static/hv_logo.svg');
  }

  render() {
    return (
      <div>
        <HvHeader
          cornerLink={<CornerLink name="Contact" href={'https://healthverity.com/contact/'} />}
          applicationLogo={this.applicationLogo}
          menuExists={false}
        />
      </div>
    );
  }
}
