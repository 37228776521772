import PropTypes from 'prop-types';
import React, { Component } from 'react';

import InvalidCode from './InvalidCode';
import { ROUTES, changeRoute } from '~/routing-utils';
import { logout } from '~/requests';

export default class InvalidCodeContainer extends Component {
  onLeaveModal = async () => {
    this.props.setGenericError(null);
    await logout();
    changeRoute(this.props.history, ROUTES.LOGIN);
  };

  onClickedResetPassword = () => {
    this.props.setGenericError(null);
    changeRoute(this.props.history, ROUTES.RESET_PASSWORD);
  };

  render() {
    return (
      <InvalidCode
        onLeaveModal={this.onLeaveModal}
        onClickedResetPassword={this.onClickedResetPassword}
      />
    );
  }
}
InvalidCodeContainer.propTypes = {
  history: PropTypes.object.isRequired,
  setGenericError: PropTypes.func.isRequired,
};
