import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import './styles/base-styles.scss';
import Root from './screens/Root';

const app = document.getElementById('app');

ReactDOM.render(<Root />, app);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
