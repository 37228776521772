import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Form, Input, Modal, ModalBody, ModalHeader } from 'reactstrap';

import InputRequiredError from '../../../shared/InputRequiredError';

import './set-password-form.scss';

export default class SetPasswordForm extends Component {
  state = {
    newPassword1: null,
    np1ShowRequired: false,
    newPassword2: null,
    np2ShowRequired: false,
  };

  handleNewPassword1InputChange = (event) => {
    this.setState({ newPassword1: event.target.value });
  };
  handleNewPassword2InputChange = (event) => {
    this.setState({ newPassword2: event.target.value });
  };

  onSetNewPassword = (event) => {
    event.preventDefault();

    this.setState({ np1ShowRequired: !this.state.newPassword1 });
    this.setState({ np2ShowRequired: !this.state.newPassword2 });
    this.props.onSetNewPassword(this.state.newPassword1, this.state.newPassword2);
  };

  render() {
    return (
      <Modal className="login-modal" isOpen={true}>
        <ModalHeader>Set new password</ModalHeader>
        <ModalBody>
          <Form className="hv-form" onSubmit={this.onSetNewPassword}>
            <Input
              type="password"
              onChange={this.handleNewPassword1InputChange}
              placeholder="New password"
            />
            <InputRequiredError show={this.state.np1ShowRequired} />

            <Input
              type="password"
              onChange={this.handleNewPassword2InputChange}
              placeholder="Retype new password"
            />
            <InputRequiredError show={this.state.np2ShowRequired} />

            <p className="form-helper-text">
              Password must be 12-64 characters and contain at least one uppercase letter, one
              lowercase letter, one number, and one special character.
            </p>
            <div className="button-container--align-right">
              <button type="button" onClick={this.props.onLeaveModal}>
                Cancel
              </button>
              <button className="success" type="submit">
                Set new password
              </button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    );
  }
}
SetPasswordForm.propTypes = {
  onLeaveModal: PropTypes.func.isRequired,
  onSetNewPassword: PropTypes.func.isRequired,
};
