import PropTypes from 'prop-types';
import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import './terms-of-service.scss';

export default function TOS({ tosContent, tosCreated, onTOSAccept, onTOSDecline }) {
  return (
    <Modal className="tos login-modal" isOpen={true}>
      <ModalHeader>
        Terms of service <span className="last-updated">Last updated - {tosCreated}</span>
      </ModalHeader>
      <ModalBody>{tosContent}</ModalBody>
      <ModalFooter>
        <div className="button-container--align-right">
          <button className="danger" onClick={onTOSDecline}>
            Decline
          </button>
          <button className="success" onClick={onTOSAccept}>
            Accept
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
}
TOS.propTypes = {
  tosContent: PropTypes.string,
  tosCreated: PropTypes.string,
  onTOSAccept: PropTypes.func.isRequired,
  onTOSDecline: PropTypes.func.isRequired,
};
