import PropTypes from 'prop-types';
import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import './password-set.scss';

export default function PasswordSet({ onLeaveModal }) {
  return (
    <Modal className="password-successfully-set login-modal" isOpen={true}>
      <ModalHeader>New password set</ModalHeader>
      <ModalBody>
        <div className="alert alert-success">Your password has been successfully reset.</div>
        <div className="button-container--align-right">
          <button type="button" className="success" onClick={onLeaveModal}>
            Sign in
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
}
PasswordSet.propTypes = { onLeaveModal: PropTypes.func.isRequired };
