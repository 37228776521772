import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export default function SessionTimeoutModal({ onLeaveModal }) {
  return (
    <Modal className="login-modal" isOpen={true}>
      <ModalHeader>Thank you for visiting</ModalHeader>
      <ModalBody>
        <div className="alert alert-success alert--no-margin">Your session has expired.</div>
        <div className="button-container--align-right">
          <button
            type="button"
            className="success"
            id="sign-out-success-btn"
            onClick={onLeaveModal}
          >
            Okay
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
}
SessionTimeoutModal.propTypes = {
  onLeaveModal: PropTypes.func.isRequired,
};
