import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { changeRoute } from '~/routing-utils.js';
import { sessionTimeout } from '~/requests';

import SessionTimeoutModal from './SessionTimeoutModal';

export default class SessionTimeoutModalContainer extends Component {
  onLeaveModal = async () => {
    await sessionTimeout();
    changeRoute(this.props.history, '/');
  };

  render() {
    return <SessionTimeoutModal onLeaveModal={this.onLeaveModal} />;
  }
}
SessionTimeoutModalContainer.propTypes = {
  history: PropTypes.object.isRequired,
};
