import React from 'react';
import PropTypes from 'prop-types';

import './marketing-content.scss';

export default function Marketing({ marketingContent }) {
  // marketingContent contains a multitude of settings that inform
  // what should be displayed by this Component

  const backgroundCustomStyle = {
    backgroundImage: 'url(' + marketingContent.background_image + ')',
  };
  const panelCustomStyle = {
    backgroundImage: 'url(' + marketingContent.panel_header_image + ')',
  };
  const tagCustomStyle = {
    background: '#' + marketingContent.tag_background_color,
    color: '#' + marketingContent.tag_text_color,
  };

  return (
    <div className="marketing-page-background" style={backgroundCustomStyle}>
      <div className="marketing-panel-body">
        <div className="marketing-panel">
          <div className="marketing-header-box" style={panelCustomStyle}>
            <div className="marketing-header-content">
              <div className="marketing-header__tag" style={tagCustomStyle}>
                {marketingContent.tag_text}
              </div>
            </div>
          </div>

          <div className="marketing-panel__description">
            <pre
              dangerouslySetInnerHTML={{
                __html: marketingContent.description,
              }}
            />
            <a className="link-button" href={marketingContent.button_url} target="_blank">
              {marketingContent.button_text}
            </a>
          </div>
        </div>
      </div>
      <div className="marketing-page-footer" />
    </div>
  );
}
Marketing.propTypes = {
  marketingContent: PropTypes.object.isRequired,
};
