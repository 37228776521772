import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { ROUTES, changeRoute, redirectToMarketplace } from '~/routing-utils.js';
import { acceptTermsOfService, getTermsOfService, logout } from '~/requests';

import TOS from './TOS';

export default class TOSContainer extends Component {
  state = { tosContent: null };

  async componentDidMount() {
    if (!this.state.tosContent) {
      const tos = await getTermsOfService();
      this.setState({ tosContent: tos.content });
      this.setState({ tosCreated: tos.created });
    }
  }

  onTOSAccept = async () => {
    const successfullyAccepted = await acceptTermsOfService();
    if (successfullyAccepted) {
      redirectToMarketplace();
      return;
    }
  };

  onTOSDecline = async () => {
    await logout();
    changeRoute(this.props.history, ROUTES.LOGIN);
  };

  render() {
    return (
      <TOS
        tosContent={this.state.tosContent}
        tosCreated={this.state.tosCreated}
        onTOSAccept={this.onTOSAccept}
        onTOSDecline={this.onTOSDecline}
      />
    );
  }
}
TOSContainer.propTypes = {
  history: PropTypes.object.isRequired,
};
