import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { get as softGet } from 'lodash';

import { ROUTES, changeRoute, redirectToMarketplace } from '~/routing-utils.js';
import { logout, verify } from '~/requests';

import Verification from './Verification';

export default class VerificationContainer extends Component {
  onAttemptVerify = async (token, publicDevice) => {
    this.props.setGenericError(null);

    if (!token) {
      this.props.setGenericError('The token was entered incorrectly. Please try again.');
      return;
    }

    const verificationStatus = await verify(token, publicDevice);

    // Failed verification
    if (!verificationStatus.verified) {
      const errors = verificationStatus.errors;
      if (Array.isArray(errors)) {
        errors = errors.join('\n');
      }
      this.props.setGenericError(errors);
      return;
    }

    // Verified, but needs to accept TOS
    if (!verificationStatus.acceptedTOS) {
      changeRoute(this.props.history, ROUTES.TOS);
      return;
    }

    // Success!
    redirectToMarketplace();
    return;
  };

  onLeaveModal = async () => {
    this.props.setGenericError(null);

    await logout();
    changeRoute(this.props.history, ROUTES.LOGIN);
  };

  render() {
    return (
      <Verification
        tokenInitialValue={softGet(this.props.match, 'params.code', '')}
        onAttemptVerify={this.onAttemptVerify}
        onLeaveModal={this.onLeaveModal}
      />
    );
  }
}
VerificationContainer.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  setGenericError: PropTypes.func.isRequired,
};
