import React, { Component } from 'react';

import Content from './Content';
import { getActiveLoginMarketingContent } from '~/requests';

export default class ContentContainer extends Component {
  state = { marketingContent: null };

  async componentDidMount() {
    const marketingContent = await getActiveLoginMarketingContent();
    if (marketingContent !== null) {
      this.setState({
        marketingContent: marketingContent,
      });
    }
  }

  render() {
    return <Content marketingContent={this.state.marketingContent} />;
  }
}
