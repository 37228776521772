import PropTypes from 'prop-types';
import React, { Component } from 'react';

import EmailSent from './EmailSent';
import { ROUTES, changeRoute } from '~/routing-utils';
import { logout } from '~/requests';

export default class EmailSentContainer extends Component {
  onTryDifferentEmail = async () => {
    this.props.setGenericError(null);
    changeRoute(this.props.history, ROUTES.RESET_PASSWORD);
  };

  onLeaveModal = async () => {
    this.props.setGenericError(null);

    await logout();
    changeRoute(this.props.history, ROUTES.LOGIN);
  };

  render() {
    return (
      <EmailSent onTryDifferentEmail={this.onTryDifferentEmail} onLeaveModal={this.onLeaveModal} />
    );
  }
}
EmailSentContainer.propTypes = { history: PropTypes.object.isRequired };
