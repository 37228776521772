import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { get as softGet } from 'lodash';

import SetPasswordForm from './SetPasswordForm';
import { ROUTES, changeRoute } from '~/routing-utils';
import { TOKEN_EXPIRED, logout, checkResetPasswordTokenValid, setNewPassword } from '~/requests';

export default class SetPasswordFormContainer extends Component {
  constructor(props) {
    super(props);

    // Set b64UserId and resetToken based on the URL
    this.state = {
      b64UserId: softGet(this.props.match, 'params.b64UserId', ''),
      resetToken: softGet(this.props.match, 'params.resetToken', ''),
      modalCanBeShown: false,
      urlIsValid: false,
      passwordHasBeenSet: false,
    };
  }
  handleUrlParameters = async (b64UserId, resetToken) => {
    // Asynchronously check if the token supplied in the URL is valid
    const tokenValid = await checkResetPasswordTokenValid(b64UserId, resetToken);

    if (!tokenValid) {
      changeRoute(this.props.history, ROUTES.SET_PASSWORD_INVALID_CODE);
      return;
    }

    this.setState({ modalCanBeShown: true });
  };
  componentDidMount() {
    // Get link parameters from Url
    this.handleUrlParameters(this.state.b64UserId, this.state.resetToken);
  }

  onLeaveModal = async () => {
    this.props.setGenericError(null);
    await logout();
    changeRoute(this.props.history, ROUTES.LOGIN);
  };

  onSetNewPassword = async (newPassword1, newPassword2) => {
    this.props.setGenericError(null);

    if (newPassword1 !== newPassword2) {
      this.props.setGenericError("The two passwords didn't match");
      return;
    }

    const validationError = await setNewPassword(
      this.state.b64UserId,
      this.state.resetToken,
      newPassword1,
      newPassword2
    );

    if (validationError === null) {
      changeRoute(this.props.history, ROUTES.PASSWORD_SET);
    } else {
      if (validationError === TOKEN_EXPIRED) {
        changeRoute(this.props.history, ROUTES.SET_PASSWORD_INVALID_CODE);
        return;
      } else {
        this.props.setGenericError(validationError);
        return;
      }
    }
  };

  render() {
    if (!this.state.modalCanBeShown) {
      // Wait for backend to let us know if the token is valid (handleUrlParameters())
      return <div></div>;
    }

    return (
      <SetPasswordForm onLeaveModal={this.onLeaveModal} onSetNewPassword={this.onSetNewPassword} />
    );
  }
}
SetPasswordFormContainer.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  setGenericError: PropTypes.func.isRequired,
};
