import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { CustomInput, Form, Input, Modal, ModalBody, ModalHeader } from 'reactstrap';

import './verification.scss';

export default class Verification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: this.props.tokenInitialValue || '',
      publicDevice: false,
      tokenFieldError: null,
    };
  }

  attemptVerify = (event) => {
    event.preventDefault();

    if (!this.state.token) {
      this.setState({ tokenFieldError: 'This is a required field' });
    } else {
      this.setState({ tokenFieldError: null });
    }
    this.props.onAttemptVerify(this.state.token, this.state.publicDevice);
    this.setState({ token: '' });
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({ [name]: value });
  };

  render() {
    let tokenFieldErrorElement = null;
    if (this.state.tokenFieldError) {
      tokenFieldErrorElement = (
        <div className="field-level-error">
          <div className="arrow" />
          <div className="field-level-error__content">{this.state.tokenFieldError}</div>
        </div>
      );
    }

    return (
      <Modal className="login-modal" isOpen={true}>
        <ModalHeader>Two-factor authentication</ModalHeader>
        <ModalBody>
          <Form className="hv-form" onSubmit={this.attemptVerify}>
            <div className="alert alert-success">
              <div>Please enter the verification token that was emailed to you.</div>
              If you do not receive an email from HealthVerity Marketplace, please check your
              junk/spam folder or contact HealthVerity.
            </div>

            <Input
              id="token"
              className="token-input"
              name="token"
              value={this.state.token}
              onChange={this.handleInputChange}
              placeholder="Enter token (see email)"
            />
            {tokenFieldErrorElement}

            <CustomInput
              id={1}
              type="checkbox"
              name="publicDevice"
              checked={this.state.publicDevice}
              onChange={this.handleInputChange}
              label="This is a public or shared computer"
            />
            <div className="button-container--align-right">
              <button type="button" onClick={this.props.onLeaveModal}>
                Cancel
              </button>
              <button className="success" type="submit">
                Verify
              </button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    );
  }
}
Verification.propTypes = {
  tokenInitialValue: PropTypes.string,
  onAttemptVerify: PropTypes.func.isRequired,
  onLeaveModal: PropTypes.func.isRequired,
};
