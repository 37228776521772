import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export default function LockedOutModal({ onLeaveModal }) {
  return (
    <Modal className="lockout-modal" isOpen={true}>
      <ModalHeader>Account locked</ModalHeader>
      <ModalBody>
        <div className="alert alert-success">
          Your account is locked for 15 minutes. Please select Forgot Password to reset your
          password or email{' '}
          <a href="mailto:support@healthverity.com" target="_blank">
            support@healthverity.com
          </a>{' '}
          for further assistance.
        </div>
        <div className="button-container--align-right">
          <button type="button" className="success" onClick={onLeaveModal}>
            Close
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
}
LockedOutModal.propTypes = {
  onLeaveModal: PropTypes.func.isRequired,
};
