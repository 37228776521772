import PropTypes from 'prop-types';
import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import './email-sent.scss';

export default function EmailSent({ onTryDifferentEmail, onLeaveModal }) {
  return (
    <Modal className="email-sent login-modal" isOpen={true}>
      <ModalHeader>Reset email sent</ModalHeader>
      <ModalBody>
        <div className="alert alert-success">
          <div>You will receive an email containing instructions for resetting your password.</div>
          <br />
          <div>
            If you do not receive an email, please check your spam folder or make sure that the
            email entered is the same as the email with which you registered.
          </div>
        </div>
        <div className="button-container--align-right">
          <button type="button" className="success" onClick={onLeaveModal}>
            Okay
          </button>
        </div>

        <hr id="email-sent-divider" />

        <p id="not-working-text" className="text--normal text--bold">
          Not working?
        </p>
        <p>
          Please try a different email or{' '}
          <a href={'mailto:support@healthverity.com'} target="_blank">
            contact HealthVerity
          </a>{' '}
          for help.
        </p>
        <button type="button" onClick={onTryDifferentEmail}>
          Try a different email
        </button>
      </ModalBody>
    </Modal>
  );
}
EmailSent.propTypes = {
  onTryDifferentEmail: PropTypes.func.isRequired,
  onLeaveModal: PropTypes.func.isRequired,
};
