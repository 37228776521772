import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link, Route } from 'react-router-dom';

import InvalidCode from './components/NewPassword/InvalidCode';
import PasswordSet from './components/NewPassword/PasswordSet';
import SetPasswordForm from './components/NewPassword/SetPasswordForm';
import SignedOutModal from './components/SignedOutModal';
import SessionTimeoutModal from './components/SessionTimeoutModal';
import EmailSent from './components/ResetPassword/EmailSent';
import SendResetEmail from './components/ResetPassword/SendResetEmail';
import TOS from './components/TOS';
import Verification from './components/Verification';
import LockedOutModal from './components/LockedOutModal';

import { ROUTES } from '~/routing-utils.js';

import './login.scss';

const MARKET_LOGO = require('../../../../../../shared/static/hv_marketplace_logo.png');
const DEMO_URL = 'https://healthverity.com/request-demo/';

export default class Login extends Component {
  state = {
    username: null,
    password: null,
    genericError: null,
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({ [name]: value });
  };

  getQueryVariable = (variableName) => {
    let query = window.location.search;
    let queryParams = new URLSearchParams(query);
    return queryParams.get(variableName);
  };

  attemptLogin = (event) => {
    event.preventDefault();

    const kbParameter = this.getQueryVariable('knowledgeBase');
    const useKnowledgeBase = kbParameter !== null ? kbParameter.toLowerCase() === 'true' : false;

    this.props.onLoginSubmit(this.state.username, this.state.password, useKnowledgeBase);
  };

  renderLoginValidationError = () => {
    if (this.props.loginValidationMessage) {
      return (
        <div className="login-validation-error" id="login-validation-error">
          {this.props.loginValidationMessage}
        </div>
      );
    }
  };

  setGenericError = (genericError) => {
    this.setState({ genericError: genericError });
  };
  renderGenericValidationError = () => {
    if (this.state.genericError) {
      return <div className="generic-validation-error">{this.state.genericError}</div>;
    }
  };

  render() {
    return (
      <div className="login-page">
        {this.renderGenericValidationError()}
        {this.renderLoginValidationError()}

        <Route
          path={[ROUTES.VERIFY_WITH_CODE, ROUTES.VERIFY]}
          render={(props) => <Verification {...props} setGenericError={this.setGenericError} />}
        />

        <Route path={ROUTES.TOS} component={TOS} />

        <Route
          exact
          path={ROUTES.RESET_PASSWORD}
          render={(props) => <SendResetEmail {...props} setGenericError={this.setGenericError} />}
        />
        <Route
          path={ROUTES.RESET_PASSWORD_EMAIL_SENT}
          render={(props) => <EmailSent {...props} setGenericError={this.setGenericError} />}
        />

        <Route
          path={ROUTES.SET_PASSWORD_FORM}
          render={(props) => <SetPasswordForm {...props} setGenericError={this.setGenericError} />}
        />
        <Route
          path={ROUTES.SET_PASSWORD_INVALID_CODE}
          render={(props) => <InvalidCode {...props} setGenericError={this.setGenericError} />}
        />
        <Route
          path={ROUTES.PASSWORD_SET}
          render={(props) => <PasswordSet {...props} setGenericError={this.setGenericError} />}
        />
        <Route path="/signed_out/" component={SignedOutModal} />
        <Route path="/session_timeout/" component={SessionTimeoutModal} />
        <Route
          path={ROUTES.ACCOUNT_LOCKED}
          render={(props) => <LockedOutModal {...props} setGenericError={this.setGenericError} />}
        />

        <div className="login-form-panel-container">
          <div className="login-form-panel">
            <div className="login-form-panel__header">
              <img
                className="login-form-panel__logo"
                src={MARKET_LOGO}
                width="234"
                height="45"
                alt="Marketplace logo"
              />
            </div>
            <hr />
            <form className="hv-form" onSubmit={this.attemptLogin}>
              <p className="input-header">Username / Email</p>
              <input
                type="text"
                name="username"
                onChange={this.handleInputChange}
                placeholder="Enter username / email"
                id="login-username-input"
              />

              <p className="input-header">Password</p>
              <input
                type="password"
                name="password"
                onChange={this.handleInputChange}
                placeholder="Enter password"
                id="login-password-input"
              />
              <input type="text" name="knowledgeBase" hidden={true} id="login-knowledge-base" />
              <div id="forgot-password-container">
                <Link to="/reset_password">Forgot password?</Link>
              </div>
              <input type="submit" value="Sign in" id="sign-in-btn" />
            </form>
          </div>
        </div>
        <div className="demo-request-container">
          <span>Not a client yet?</span>
          <a className="link-button" href={DEMO_URL} target="_blank">
            Request a demo
          </a>
        </div>
      </div>
    );
  }
}
Login.propTypes = {
  loginValidationMessage: PropTypes.string,
  onLoginSubmit: PropTypes.func.isRequired,
};
