import PropTypes from 'prop-types';
import React, { Component } from 'react';

import SendResetEmail from './SendResetEmail';
import { ROUTES, changeRoute } from '~/routing-utils';
import { logout, sendResetPasswordEmail } from '~/requests';

export default class SendResetEmailContainer extends Component {
  componentDidMount() {
    // If a User sees this modal, they should be logged out.
    logout();
  }

  onRequestPasswordReset = async (email) => {
    this.props.setGenericError(null);

    // simple regex for email
    const regexEmail = new RegExp('^[^@]+@([^.]+\\.)+[^.]+$');
    if (!email || !regexEmail.test(email)) {
      this.props.setGenericError('Enter a valid email address');
      return;
    }

    // We change route to say that the email was sent regardless of the response,
    // to give the user no information about whether the email they put was in our system
    changeRoute(this.props.history, ROUTES.RESET_PASSWORD_EMAIL_SENT);

    await sendResetPasswordEmail(email);
  };

  onLeaveModal = async () => {
    this.props.setGenericError(null);

    await logout();
    changeRoute(this.props.history, ROUTES.LOGIN);
  };

  render() {
    return (
      <SendResetEmail
        onRequestPasswordReset={this.onRequestPasswordReset}
        onLeaveModal={this.onLeaveModal}
      />
    );
  }
}
SendResetEmailContainer.propTypes = {
  history: PropTypes.object.isRequired,
  setGenericError: PropTypes.func.isRequired,
};
