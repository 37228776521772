import PropTypes from 'prop-types';
import React, { Component } from 'react';

import PasswordSet from './PasswordSet';
import { ROUTES, changeRoute } from '~/routing-utils';
import { logout } from '~/requests';

export default class PasswordSetContainer extends Component {
  onLeaveModal = async () => {
    this.props.setGenericError(null);
    await logout();
    changeRoute(this.props.history, ROUTES.LOGIN);
  };

  render() {
    return <PasswordSet onLeaveModal={this.onLeaveModal} />;
  }
}
PasswordSetContainer.propTypes = { history: PropTypes.object.isRequired };
