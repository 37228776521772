import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { changeRoute } from '~/routing-utils.js';
import { logout } from '~/requests';

import SignedOutModal from './SignedOutModal';

export default class SignedOutModalContainer extends Component {
  onLeaveModal = async () => {
    await logout();
    changeRoute(this.props.history, '/');
  };

  render() {
    return <SignedOutModal onLeaveModal={this.onLeaveModal} />;
  }
}
SignedOutModalContainer.propTypes = {
  history: PropTypes.object.isRequired,
};
