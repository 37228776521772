// Constants
import { attemptActivateAccount, getUserDetails } from '~/requests';

export const userRoles = [
  'Select role',
  'Analyst',
  'Consultant',
  'Marketer',
  'Executive',
  'Strategist',
  'Other',
];

// Default userProfile
export const defaultUserProfile = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  role: userRoles[0],
  password: '',
};

/**
 * Gets basic userInfo for the given
 * activation key
 */
export function getUserInfo(activationKey) {
  return getUserDetails(activationKey).then((data) => {
    const updatedProfile = { ...defaultUserProfile };
    if (data.first_name) {
      updatedProfile.firstName = data.first_name;
    }
    if (data.last_name) {
      updatedProfile.lastName = data.last_name;
    }
    return updatedProfile;
  });
}

/**
 * Attempts to activate user based on
 * their entered data and activation key
 */
export function attemptActivate(userProfile, activationKey) {
  const userDataPayload = getUserProfilePayload(userProfile);
  const userFormData = getJsonAsFormData(userDataPayload);
  return attemptActivateAccount(activationKey, userFormData);
}

/**
 * Function to format a phoneNumber string
 * in USA format that looks like "+1 (555) 555-5555"
 */
export function formatPhoneNumber(phoneNumberString) {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return phoneNumberString;
}

/**
 * Converts userProfile JS object into
 * JSON payload to be sent to backend
 */
function getUserProfilePayload(userProfile) {
  return {
    first_name: userProfile.firstName,
    last_name: userProfile.lastName,
    phone_number: userProfile.phoneNumber || null,
    role: userProfile.role === defaultUserProfile.role ? null : userProfile.role,
    password: userProfile.password,
  };
}

/**
 * Converts jsonObject into formData object
 */
function getJsonAsFormData(jsonObj) {
  const formData = new FormData();
  for (let key in jsonObj) {
    formData.append(key, jsonObj[key]);
  }
  return formData;
}
