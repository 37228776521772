import axios from 'axios';
import Cookies from 'js-cookie';
import queryString from 'query-string';
import { get as softGet } from 'lodash';
import { redirectToLogin } from './routing-utils';

export const TOKEN_EXPIRED = 'TOKEN_EXPIRED';

const request = axios.create({
  baseURL: '/',
});

/**
 * @returns csrftoken from cookies
 */
const getCSRFToken = () => {
  const csrfCookieName = document.getElementById('app').getAttribute('data-csrf_cookie_name');
  return Cookies.get(csrfCookieName);
};

class LoginStatus {
  loginSuccessful = false;
  accountLocked = false;
  lockedTriesRemaining = 0;
  statusCode = 0;
  redirectUrl = '';

  constructor(success, locked, remaining, statusCode, redirectUrl) {
    this.loginSuccessful = success;
    this.accountLocked = locked;
    this.lockedTriesRemaining = remaining;
    this.statusCode = statusCode;
    this.redirectUrl = redirectUrl;
  }
}

class VerificationStatus {
  verified = false;
  acceptedTOS = false;
  errors = [];

  constructor(ver, tos, err) {
    this.verified = Boolean(ver);
    this.acceptedTOS = Boolean(tos);
    this.errors = String(err);
  }
}

function verificationStatusFromResponse(response) {
  const responseData = softGet(response, 'data', {});
  return new VerificationStatus(
    responseData.verified,
    responseData.accepted_tos,
    responseData.errors
  );
}

/**
 * Logs a user into marketplace
 * @param username
 * @param password
 * @param useKnowledgeBase
 * @returns a boolean reflecting whether the user has been successfully logged
 * in or not
 */
export const login = async (username, password, useKnowledgeBase = false) => {
  const useKnowledgeBaseUrl = useKnowledgeBase ? '?knowledgeBase=true' : '';
  const url = 'login/' + useKnowledgeBaseUrl;

  const response = await request.post(url, queryString.stringify({ username, password }), {
    headers: { 'X-CSRFToken': getCSRFToken(), 'Access-Control-Allow-Credentials': true },
  });

  const responseData = softGet(response, 'data', {});

  if (responseData['status_code'] === 302) {
    return new LoginStatus(
      responseData.login_successful,
      responseData.account_locked,
      responseData.locked_tries_remaining,
      responseData.status_code,
      responseData.redirect_url || ''
    );
  }

  return new LoginStatus(
    responseData.login_successful,
    responseData.account_locked,
    responseData.locked_tries_remaining
  );
};

/**
 * Logs a user out of marketplace
 */
export const logout = async () => await request.get('logout/');

/**
 * Logs a user out of marketplace and expires their session
 */
export const sessionTimeout = async () => await request.get('timeout/');

/**
 * Verifies whether the user needs to login using two-factor authentication and
 * whether the user has accepted the terms of service
 * @returns If the request was successful, returns an object containing either a
 * validation message or a boolean value reflecting whether the user accepted
 * the terms of service. Otherwise, returns {}.
 */
export const getVerificationDetails = async (search) => {
  const response = await request.get('verify/' + search);
  return verificationStatusFromResponse(response);
};

/**
 * Verifies the token submitted for two-factor authentication
 * @param token
 * @param publicDevice
 * @returns If the request was successful, returns an object containing either a
 * validation message or a boolean value reflecting whether the user accepted
 * the terms of service. Otherwise, returns {}.
 */
export const verify = async (token, publicDevice) => {
  const data = publicDevice
    ? queryString.stringify({
        otp_token: token,
        public_device: 'public_device',
      })
    : queryString.stringify({ otp_token: token });
  const response = await request.post('verify/', data, {
    headers: { 'X-CSRFToken': getCSRFToken() },
  });

  return verificationStatusFromResponse(response);
};

/**
 * Gets user profile details based on
 * query param's activation key
 */
export const getUserDetails = (activationKey) => {
  return request
    .get('activate_user?ak=' + activationKey)
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
    })
    .catch((error) => {
      console.log(error);
      redirectToLogin();
    });
};

/**
 * Attempt to activate and update the user for given
 * activation key and userProfile data
 */
export const attemptActivateAccount = (activationKey, userProfileFormData) => {
  return request.post('activate_user?ak=' + activationKey, userProfileFormData, {
    headers: {
      'X-CSRFToken': getCSRFToken(),
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};

/**
 * @returns the currently active terms of service
 */
export const getTermsOfService = async () => {
  const response = await request.get('active_tos/');
  return response.status === 200 ? response.data : null;
};

/**
 * Accepts the currently active terms of service
 */
export const acceptTermsOfService = async () =>
  await request.post('terms-of-service/confirm/', 'accept=accept', {
    headers: { 'X-CSRFToken': getCSRFToken() },
  });

/**
 * Get custom login marketing content from the backend
 */
export const getActiveLoginMarketingContent = async () => {
  const response = await request.get('api/v0/marketplace/login_marketing_content/?is_active=true');

  if (response.status !== 200 || response.data.length === 0) {
    return null;
  }
  return response.data[0];
};

/*
 * Given a User's email, ask the backend to send that user a password reset email.
 */
export const sendResetPasswordEmail = async (email) => {
  let response = null;
  try {
    response = await request.post('generate_reset_password_token/', `email=${email}`, {
      headers: { 'X-CSRFToken': getCSRFToken() },
    });
  } catch {
    // Something went wrong with this backend call.
    return false;
  }

  if (response.status !== 200 || response.data.length === 0) {
    // Something went wrong with this backend call.
    return false;
  }

  // Reset password email should be sent assuming the email is for a real User
  return true;
};

/*
 * Given the content of a password reset email,
 * check to see that the information is still valid.
 */
export const checkResetPasswordTokenValid = async (b64UserId, resetToken) => {
  let response = null;
  try {
    response = await request.get(`check_reset_password_token/${b64UserId}/${resetToken}/`);
  } catch {
    // If the response contains no data or doesn't have a 200 HTTP code,
    // the token isn't valid.
    return false;
  }

  if (response.status !== 200 || response.data.length === 0) {
    // Token isn't valid if there's an error
    return false;
  }

  // Return whether the status in "response.data" is "VALID"
  return softGet(response.data, 'success', false);
};

/*
 * Given the two entered instances of a User's new password, and the contents of a
 * password reset email, attempt to set this User's password.
 */
export const setNewPassword = async (b64UserId, resetToken, newPassword1, newPassword2) => {
  let response = null;
  try {
    let newPassword1Encoded = encodeURIComponent(newPassword1);
    let newPassword2Encoded = encodeURIComponent(newPassword2);
    response = await request.post(
      `set_new_password/${b64UserId}/${resetToken}/`,
      `new_password1=${newPassword1Encoded}&new_password2=${newPassword2Encoded}`,
      { headers: { 'X-CSRFToken': getCSRFToken() } }
    );
  } catch {
    // If there's an error, return that the token expired.
    return TOKEN_EXPIRED;
  }

  if (response.status !== 200 || response.data.length === 0) {
    // If the response contains no data or doesn't have a 200 HTTP code,
    // return that the token expired.
    return TOKEN_EXPIRED;
  }

  const success = softGet(response.data, 'success', false);
  if (!success) {
    // Return the error that caused the "set password" action to fail.
    // The backend will return that the failure lies with the "new_password2" field.
    return softGet(response.data, 'errors', []).join('\n');
  }

  // No issues, return no error message.
  return null;
};
